
window.addEventListener('DOMContentLoaded', () => {
  const HTML = document.querySelector('html');
  const elts = document.querySelectorAll('.template-fresh:not(.ready)')

  if( elts ) {
    elts.forEach(el => {

      const xhr = new XMLHttpRequest();
      xhr.open("GET", 'https://www.fresh.fr/get-template/' + el.dataset.template);
      xhr.send();
      xhr.onload = function(e) {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          el.innerHTML = xhr.responseText;
          el.classList.add('ready')

          /**
           * Toggle menu
           */
          const toggleMenu = document.querySelectorAll('.toggle-menu, .close-menu');
          const bannerAlert = document.querySelector('.bandeau-alert');
          if (toggleMenu) {
            function openCloseMenu(e) {
              e.preventDefault();
    
              if (bannerAlert && window.scrollY < bannerAlert.offsetHeight) {
                window.scrollTo({
                  top: bannerAlert.offsetHeight,
                  behavior: "smooth"
                });
              }
    
              HTML.classList.toggle('show-menu');
            }
            toggleMenu.forEach(el => {
              el.removeEventListener('click', openCloseMenu)
              el.addEventListener('click', openCloseMenu)
            })
          }

          /**
           * Sticky menu
           */
          const stickys = document.querySelectorAll('[data-sticky]:not(.init)');

          if (stickys) {
            stickys.forEach(el => {
              el.classList.add('init')
              const parent = el.parentElement;

              window.addEventListener('resize', (e) => {
                window.dispatchEvent(new Event('scroll'));
              })

              window.addEventListener('scroll', (e) => {
                const pos = el.getBoundingClientRect();
                const pPos = parent.getBoundingClientRect();
                const offset = el.dataset.stickyOffset ? document.querySelector(el.dataset.stickyOffset).offsetHeight : 0;
                const container = el.dataset.stickyContainer ? document.querySelector(el.dataset.stickyContainer) : false;
                const containerPos = container ? container.getBoundingClientRect() : 0;

                // Now sticky
                if (pPos.top - offset <= 0) {
                  el.style.width = `${pPos.width}px`
                  if (container && containerPos.bottom - pos.height - offset <= 0) {
                    el.style.position = 'absolute'
                    el.style.top = `auto`
                    el.style.bottom = `0px`
                    el.style.left = `0px`
                  } else {
                    el.style.position = 'fixed'
                    el.style.top = `${offset}px`
                    el.style.bottom = `auto`
                    el.style.left = `${pPos.x}px`
                  }
                } else {
                  el.style.position = 'absolute'
                  el.style.top = `0px`
                  el.style.bottom = `auto`
                  el.style.left = `0px`
                  el.style.width = `100%`
                }
              })

              window.dispatchEvent(new Event('scroll'));
            })
          }

        }
      }
   });  
  }
})